import { useState } from 'react';

import {
  ArrowDownTrayIcon as DownloadIcon,
  EyeIcon,
} from '@heroicons/react/24/outline';

import filesAPI from '@/api/files';
import CustomCard from '@/components/ReOrderPage/shared/CustomCard.png';
import IFile from '@/types/IFile';
import { IStep4 } from '@/types/ReOrderExperience/IStep4';
import { Box } from '@material-ui/core';

import Badge from '../../Badge';
import Button, { BUTTON_KIND, BUTTON_SIZE } from '../../Button';
import Spinner from '../../Icons/Spinner';
import InfoPanelDivider from '../../InfoPanelDivider';

const MoreOrderShippingInformation = (props: IStep4) => {
  const { information, shippingInformation, design, totalPrice } = props;
  const [isDownloading, setIsDownloading] = useState(false);

  const download = async (designFile: IFile) => {
    setIsDownloading(true);

    try {
      const response = await filesAPI.downloadFile(designFile.original_url);

      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(new Blob([response.data]));
      a.download = designFile.name;
      a.click();
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <>
      <Box>
        <p className="text-2xl font-medium leading-6 text-gray-900 mb-2">
          Order details
        </p>
        <Box className="grid grid-cols-8 gap-4 p-6 border border-gray-200 rounded-lg mt-8">
          <Box className="col-span-2">
            <img src={CustomCard} alt="CustomCard" />
          </Box>
          <Box className="col-span-2 font-poppins font-medium text-sm text-gray-900">
            <p className=" ">Tapt Custom Digital Business Card</p>

            <p className="text-brand-500 font-normal mt-3">
              Premium account holder
            </p>
            <p className="font-normal">$40 per card</p>
          </Box>
          <Box className="col-span-2 font-poppins font-normal text-sm text-gray-900">
            <p className="font-medium">Delivery Address</p>
            <p className="mt-3">{shippingInformation.company}</p>
            <p>{shippingInformation.address}</p>
            <p>{shippingInformation.city}</p>
            <p>{shippingInformation.postCode}</p>
            <p>{shippingInformation.countryRegion}</p>
          </Box>
          <Box className="col-span-1 font-poppins font-normal text-sm text-gray-900">
            <p className="font-medium">Quantity</p>
            <p className="mt-3">
              {information.length} {information.length > 1 ? 'cards' : 'card'}
            </p>
          </Box>
          <Box className="col-span-1 font-poppins font-normal text-sm text-gray-900">
            <p className="font-medium">Total price</p>
            <p className="mt-3">$ {totalPrice.toFixed(2)}</p>
          </Box>{' '}
        </Box>
      </Box>
      <InfoPanelDivider />
      <Box>
        <p className="text-2xl font-medium leading-6 text-gray-900 mb-2">
          Card design
        </p>
        <Box className="grid grid-cols-12 border border-gray-200 rounded-lg mt-8 p-6">
          <Box className="col-span-2 font-poppins font-medium text-sm text-gray-900">
            <p className="mb-3">Order number</p>
            <Badge
              text={
                design?.order
                  ? `Order #${design.order.order_number}`
                  : 'In progress'
              }
              kind={design?.order ? 'gray' : 'yellow'}
            />
          </Box>
          <Box className="col-span-6 font-poppins font-medium text-sm text-gray-900">
            <p className="mb-3">File name</p>
            <p className="truncate font-normal">{design?.design_file?.name}</p>
          </Box>
          <Box className="col-span-4 flex justify-end items-center">
            {!isDownloading ? (
              design?.design_file && (
                <Button
                  buttonText="Download file"
                  size={BUTTON_SIZE.XSMALL}
                  kind={BUTTON_KIND.WHITE}
                  icon={<DownloadIcon />}
                  className="mr-3"
                  onClick={() => {
                    design.design_file && download(design.design_file);
                  }}
                />
              )
            ) : (
              <Spinner className="mr-3" />
            )}
            <Button
              buttonText="View design"
              size={BUTTON_SIZE.XSMALL}
              kind={BUTTON_KIND.PRIMARY}
              icon={<EyeIcon />}
              onClick={() =>
                window.open(
                  `${import.meta.env.VITE_API_BASE_URL}${
                    design?.design_file?.original_url
                  }`,
                  '_blank',
                )
              }
            />
          </Box>
        </Box>
      </Box>
      <InfoPanelDivider />
      <Box>
        <p className="text-2xl font-medium leading-6 text-gray-900 mb-2">
          Payment due
        </p>
        <Box className="grid grid-cols-2 border border-gray-200 rounded-lg mt-8 p-6">
          <Box className="col-span-1 font-poppins font-normal text-sm text-gray-900">
            {/* <p className="font-medium">Billing address</p>
            <p className="mt-3">{shippingInformation.company}</p>
            <p>{shippingInformation.address}</p>
            <p>{shippingInformation.city}</p>
            <p>{shippingInformation.postCode}</p>
            <p>{shippingInformation.countryRegion}</p> */}
          </Box>
          <Box className="col-span-1">
            <Box className="grid grid-cols-2 border-b border-gray-400 pb-6 font-poppins text-sm font-normal text-gray-900">
              <p className="flex justify-start items-center">Subtotal</p>
              <p className="flex justify-end items-center font-medium">
                ${totalPrice}
              </p>
            </Box>
            <Box className="grid grid-cols-2 border-b border-gray-400 py-6 font-poppins text-sm font-normal text-gray-900">
              <p className="flex justify-start items-center">
                {/* {shippingCostAndTaxes?.country_tax_name || 'Tax'} (10% included) */}
                Tax
              </p>
              <p className="flex justify-end items-center font-medium">
                {/* ${taxPrice.toFixed(3)} */}
                Calculated at next step
              </p>
            </Box>
            <Box className="grid grid-cols-2 border-b border-gray-400 py-6 font-poppins text-sm font-normal text-gray-900">
              <p className="flex justify-start items-center">Shipping cost</p>
              <p className="flex justify-end items-center font-medium">
                {/* ${shippingPrice} */}
                Calculated at next step
              </p>
            </Box>

            <Box className="grid grid-cols-2 pt-6 font-poppins text-sm font-normal text-brand-500">
              <p className="flex justify-start items-center">Total</p>
              <p className="flex justify-end items-center font-medium">
                {/* ${finalPrice.toFixed(3)} */}${totalPrice.toFixed(2)}
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MoreOrderShippingInformation;
