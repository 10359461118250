import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useLocation,
} from 'react-router-dom';

import useAppState, { SettingsTab } from '@/hooks/useAppState';
import useAuth from '@/hooks/useAuth';
import AccountRecoveryPage from '@/pages/AccountRecoveryPage';
import ActivationKeysListPage from '@/pages/ActivationKeysListPage';
import AnalyticsAdminPage from '@/pages/AnalyticsAdminPage';
import AnalyticsPage from '@/pages/AnalyticsPage';
import CardsListPage from '@/pages/CardsListPage';
import CollectionAnalyticsPage from '@/pages/CollectionAnalyticsPage';
import ContactListAdminPage from '@/pages/ContactListAdminPage';
import ContactListPage from '@/pages/ContactListPage';
import CreateGroupPage from '@/pages/CreateGroupPage';
import EditGroupPage from '@/pages/EditGroupPage';
import EditProfileAdminPage from '@/pages/EditProfileAdminPage';
import EditProfilePage from '@/pages/EditProfilePage';
import EditSharedProfilePage from '@/pages/EditSharedProfilePage';
import GroupListPage from '@/pages/GroupListPage';
import HomePage from '@/pages/HomePage';
import IntegrationsPage from '@/pages/IntegrationsPage';
import LoginPage from '@/pages/LoginPage';
import NewActivationPage from '@/pages/NewActivationPage';
import NotFoundPage from '@/pages/NotFoundPage';
import OldAnalyticsPage from '@/pages/OldAnalyticsPage';
import OrdersV2ListAdminPage from '@/pages/OrderV2Page/OrdersV2ListAdmin/Page';
import OrdersListPage from '@/pages/OrdersListPage';
import OrganisationsListPage from '@/pages/OrganisationsListPage';
import PdfPocPage from '@/pages/PdfPocPage';
import PilotsListPage from '@/pages/PilotsListPage';
import PlanPage from '@/pages/PlanPage';
import PreViewPage from '@/pages/PreviewPage';
import ProfileGeneratorPage from '@/pages/ProfileGeneratorPage';
import ProfileListAdminPage from '@/pages/ProfileListAdminPage';
import ProfileListGroupPage from '@/pages/ProfileListGroupPage';
import ProfileListPage from '@/pages/ProfileListPage';
import ResetPasswordPage from '@/pages/ResetPasswordPage';
import SampleProfilePage from '@/pages/SampleProfilePage';
import AccountSettingsAdminPage from '@/pages/SettingsAdminPage';
import AccountSettingsPage from '@/pages/SettingsPage';
import AccountSettingsPreviewPage from '@/pages/SettingsPreviewPage';
import ShopPage from '@/pages/ShopPage';
import ShopPageWithoutReorder from '@/pages/ShopPageWithoutReorder';
import ShopifyLoginPage from '@/pages/ShopifyLoginPage';
import ShopifyVerifyOTPCodePage from '@/pages/ShopifyVerifyOTPCodePage';
import SignUpPage from '@/pages/SignUpPage';
import SsoConsumePage from '@/pages/SsoConsumePage';
import SuperuserAnalyticsPage from '@/pages/SuperuserAnalyticsPage';
import TestComponents from '@/pages/TestComponents';
import VerifyOTPCodePage from '@/pages/VerifyOTPCodePage';
import ViewOrganisationAdminPage from '@/pages/ViewOrganisationAdminPage';
import ViewOrganisationPage from '@/pages/ViewOrganisationPage';
import ViewProfilePage from '@/pages/ViewProfilePage';
import SubscriptionStatus from '@/types/SubscriptionStatus';

const allowReorderOnShopPage =
  import.meta.env.VITE_ALLOW_NEW_REORDER_FLOW === 'true';

function AuthenticatedRoute({ path, component }: RouteProps) {
  const { user } = useAuth();
  const { selectSettingsTab } = useAppState();

  if (!user) return <Redirect to="/login" />;

  if (
    path !== '/settings' &&
    user.platform_subscription_status === SubscriptionStatus.INACTIVE
  ) {
    selectSettingsTab(SettingsTab.MANAGE_PLAN);
    return <Redirect to="/settings" />;
  }

  return <Route exact path={path} component={component} />;
}

export default function Router() {
  const { userScope, userRole } = useAuth();
  const isSuperUser = userScope === 'admin';
  const location = useLocation();

  useEffect(() => {
    const gaMeasurementId =
      (import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID as string) ||
      'G-12345';
    ReactGA.initialize(gaMeasurementId);
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${location.pathname}${location.search}`,
    });
  }, [location]);

  return (
    <Switch>
      {isSuperUser && (
        <AuthenticatedRoute exact path="/" component={CardsListPage} />
      )}
      {isSuperUser ? (
        <AuthenticatedRoute
          exact
          path="/profiles-list"
          component={ProfileListAdminPage}
        />
      ) : (
        <AuthenticatedRoute
          exact
          path="/"
          component={
            userRole === 'org_admin' ? ProfileListPage : ProfileListGroupPage
          }
        />
      )}
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/verify-mfa" component={VerifyOTPCodePage} />
      <Route exact path="/reset-password" component={ResetPasswordPage} />
      <Route exact path="/sign-up" component={SignUpPage} />
      <Route exact path="/account-recovery" component={AccountRecoveryPage} />
      <Route exact path="/shopify/login" component={ShopifyLoginPage} />
      <Route
        exact
        path="/shopify/verify-mfa"
        component={ShopifyVerifyOTPCodePage}
      />
      <Route exact path="/sso" component={SsoConsumePage} />
      <Route
        exact
        path="/view-card/:card_hash_id"
        component={NewActivationPage}
      />
      <Route exact path="/create-group" component={CreateGroupPage} />
      <Route
        exact
        path="/view-profile/:profile_hash_id"
        component={ViewProfilePage}
      />
      <Route exact path="/view-profile-sample" component={SampleProfilePage} />
      <Route exact path="/preview/:order_uuid" component={PreViewPage} />
      {isSuperUser ? (
        <AuthenticatedRoute
          exact
          path="/settings"
          component={AccountSettingsAdminPage}
        />
      ) : (
        <AuthenticatedRoute
          exact
          path="/settings"
          component={AccountSettingsPage}
        />
      )}
      <AuthenticatedRoute
        exact
        path="/settings/preview"
        component={AccountSettingsPreviewPage}
      />
      <AuthenticatedRoute
        exact
        path="/manage-account/:account_id/:first_name/:last_name/:email"
        component={AccountSettingsPage}
      />
      {isSuperUser ? (
        <AuthenticatedRoute
          exact
          path="/contact-list"
          component={ContactListAdminPage}
        />
      ) : (
        <AuthenticatedRoute
          exact
          path="/contact-list"
          component={ContactListPage}
        />
      )}
      {isSuperUser ? (
        <AuthenticatedRoute
          exact
          path="/edit-profile/:profile_id"
          component={EditProfileAdminPage}
        />
      ) : (
        <AuthenticatedRoute
          exact
          path="/edit-profile/:profile_id"
          component={EditProfilePage}
        />
      )}
      <AuthenticatedRoute
        exact
        path="/edit-shared-profile"
        component={EditSharedProfilePage}
      />
      <AuthenticatedRoute exact path="/groups" component={GroupListPage} />
      {isSuperUser ? (
        <AuthenticatedRoute
          exact
          path="/analytics"
          component={AnalyticsAdminPage}
        />
      ) : (
        <AuthenticatedRoute
          exact
          path="/analytics"
          component={OldAnalyticsPage}
        />
      )}
      {isSuperUser ? (
        <AuthenticatedRoute
          exact
          path="/analytics2"
          component={SuperuserAnalyticsPage}
        />
      ) : (
        <AuthenticatedRoute
          exact
          path="/analytics2"
          component={AnalyticsPage}
        />
      )}
      {isSuperUser && (
        <AuthenticatedRoute
          exact
          path="/org-analytics"
          component={CollectionAnalyticsPage}
        />
      )}
      <AuthenticatedRoute exact path="/home" component={HomePage} />
      <AuthenticatedRoute
        exact
        path="/analytics"
        component={OldAnalyticsPage}
      />
      <AuthenticatedRoute exact path="/analytics2" component={AnalyticsPage} />
      {isSuperUser && (
        <AuthenticatedRoute
          exact
          path="/activation-keys"
          component={ActivationKeysListPage}
        />
      )}
      <Route exact path="/edit-group/:id" component={EditGroupPage} />
      {isSuperUser && (
        <AuthenticatedRoute
          exact
          path="/organisations-list"
          component={OrganisationsListPage}
        />
      )}
      {isSuperUser ? (
        <AuthenticatedRoute
          exact
          path="/view-organisation/:organisation_id"
          component={ViewOrganisationAdminPage}
        />
      ) : (
        <AuthenticatedRoute
          exact
          path="/view-organisation/:organisation_id"
          component={ViewOrganisationPage}
        />
      )}
      {isSuperUser && (
        <AuthenticatedRoute exact path="/orders" component={OrdersListPage} />
      )}
      {isSuperUser && (
        <Route
          exact
          path="/orders-v2/:orderDetailUUID?"
          render={({ match }) => {
            const { orderDetailUUID } = match.params;
            return <OrdersV2ListAdminPage orderDetailUUID={orderDetailUUID} />;
          }}
        />
      )}
      {isSuperUser && (
        <AuthenticatedRoute exact path="/pilots" component={PilotsListPage} />
      )}
      {isSuperUser && (
        <AuthenticatedRoute
          exact
          path="/profile-generator"
          component={ProfileGeneratorPage}
        />
      )}
      <AuthenticatedRoute
        exact
        path="/shop"
        component={allowReorderOnShopPage ? ShopPage : ShopPageWithoutReorder}
      />
      <AuthenticatedRoute
        exact
        path="/integrations"
        component={IntegrationsPage}
      />
      <AuthenticatedRoute exact path="/plan" component={PlanPage} />
      <Route exact path="/pdf" component={PdfPocPage} />
      <Route exact path="/test" component={TestComponents} />
      <Route exact path="/*" component={NotFoundPage} />
      <Route path="/404" component={NotFoundPage} />
    </Switch>
  );
}
