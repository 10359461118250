import { ChangeEvent, useCallback, useState } from 'react';

import { UserIcon } from '@heroicons/react/20/solid';
import { PhotoIcon } from '@heroicons/react/24/outline';

import filesAPI from '@/api/files';
import Spinner from '@/components/Icons/Spinner';
import ImageCropper from '@/components/ImageCropper';
import ModalStandalone from '@/components/Modals/ModalStandalone';
import { MAX_UPLOAD_FILE_SIZE } from '@/constants/files';
import classNames from '@/helpers/classNames';
import useAuth from '@/hooks/useAuth';
import IFile from '@/types/IFile';

interface IUploadPhoto {
  aspectRatio?: number;
  disabled?: boolean;
  loading?: boolean;
  photo?: IFile;
  setPhoto: React.Dispatch<React.SetStateAction<IFile | undefined>>;
  size?: string;
  title?: string;
  fileFormatMessage?: string;
  maxHeight: number;
  maxWidth: number;
}

export default function UploadPhoto({
  aspectRatio,
  disabled,
  loading,
  photo,
  setPhoto,
  size,
  title,
  fileFormatMessage,
  maxHeight,
  maxWidth,
}: IUploadPhoto) {
  const { orgID } = useAuth();

  const [uploadFileError, setUploadFileError] = useState<string | undefined>(
    undefined,
  );
  const [selectedFile, setSelectedFile] = useState('');
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isCropping, setIsCropping] = useState(false);

  const onFileSelectedCallback = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      // Callback from file input selector. send this to the image crop editor
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        if (file.size > MAX_UPLOAD_FILE_SIZE) {
          setUploadFileError('Max file size is 8MB');
          return;
        }
        const path = (window.URL || window.webkitURL).createObjectURL(file);
        if (path) {
          setSelectedFile(path);
          setUploadFileError(undefined);
        }
        setIsEditorOpen(true);
        e.target.value = '';
      }
    },
    [],
  );

  const onCropSubmit = useCallback(
    async (res: Blob) => {
      if (orgID === undefined) {
        return;
      }

      setIsCropping(true);
      setIsEditorOpen(false);

      try {
        const { data: file } = await filesAPI.createAndUploadFile(
          orgID,
          new File([res], 'image.png'),
        );

        setPhoto(file.data);
        setUploadFileError(undefined);
      } catch (err) {
        setUploadFileError('Upload file failed');
      }

      setSelectedFile('');
      setIsCropping(false);
    },
    [orgID, setPhoto],
  );

  const onEditorClose = useCallback(() => {
    setIsEditorOpen(false);
    setSelectedFile('');
  }, []);

  return (
    <>
      <div className="mb-6">
        <div className="text-sm font-medium text-gray-900 py-2">
          {title || 'Photo'}
        </div>
        <div
          className={classNames(
            size === 'large'
              ? 'mt-1 flex flex-col xl:flex-row xl:items-center'
              : 'flex items-center',
          )}
        >
          {size === 'large' ? (
            <span className="inline-flex flex-shrink-0 items-center justify-center h-44 w-fit xl:w-64 rounded-md overflow-hidden">
              {photo?.small_url ? (
                <div className="border bg-gray-100">
                  <img src={photo?.small_url} alt="Profile" />
                </div>
              ) : (
                <div className="flex items-center justify-center h-44 w-64 rounded-md overflow-hidden bg-gray-50 border-2 border-dashed">
                  <PhotoIcon className="h-24 w-24 text-gray-300" />
                </div>
              )}
            </span>
          ) : (
            <span className="inline-block h-14 w-14 rounded-full overflow-hidden bg-gray-100">
              {photo?.small_url ? (
                <img src={photo?.small_url} alt="Profile" />
              ) : (
                <div className="h-14 w-14 rounded-full bg-gray-200 overflow-hidden text-center">
                  <UserIcon className="h-14 w-14 mt-1 rounded-full text-gray-600" />
                </div>
              )}
            </span>
          )}
          {!disabled && (
            <div
              className={classNames(
                size === 'large'
                  ? 'mt-5 xl:mt-0 xl:ml-5 flex flex-col xl:self-end'
                  : 'ml-5 flex',
              )}
            >
              <div className="flex items-center justify-between xl:justify-start">
                <label
                  htmlFor="photo-upload"
                  className="cursor-pointer appearance-none rounded-md font-medium transform transition duration-300 flex items-center w-max border text-sm leading-5 py-2 px-4 text-gray-700 bg-white border-gray-300 hover:bg-gray-100 hover:no-underline focus:outline-none focus:ring-brand-700 focus:shadow-sm focus:ring-2 focus:ring-offset-2"
                >
                  <span>{photo?.small_url ? 'Change' : 'Upload a photo'}</span>
                  <input
                    id="photo-upload"
                    name="photo-upload"
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={onFileSelectedCallback}
                    className="sr-only"
                    disabled={disabled}
                  />
                  {(loading || isCropping) && (
                    <Spinner className="ml-2 text-gray-700" />
                  )}
                </label>
                {photo && (
                  <button
                    type="button"
                    onClick={() => {
                      setPhoto(undefined);
                    }}
                    className="appearance-none text-sm leading-5 font-medium text-brand-500 ml-4"
                    disabled={loading}
                  >
                    Remove
                  </button>
                )}
              </div>
              {fileFormatMessage && (
                <p className="text-sm leading-5 text-gray-400 mt-3">
                  {fileFormatMessage}
                </p>
              )}
              {uploadFileError && (
                <p className="text-sm leading-5 text-red-600 mt-3">
                  {uploadFileError}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
      <ModalStandalone isOpen={isEditorOpen} onClose={onEditorClose}>
        <div className="p-6">
          <ImageCropper
            aspectRatio={aspectRatio}
            filePath={selectedFile}
            loading={isCropping}
            onClose={onEditorClose}
            onCropSubmit={onCropSubmit}
            maxHeight={maxHeight}
            maxWidth={maxWidth}
          />
        </div>
      </ModalStandalone>
    </>
  );
}
