import { Fragment } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { IOrganisationInvite } from '@/types/IOrganisation';

import Badge, { BADGE_KIND } from '../Badge';

interface PendingInviteItemProps {
  invite: IOrganisationInvite;
  isResendInviteLoading: boolean;
  onResendInvite: (invite: IOrganisationInvite) => void;
  onDeleteInvite: (invite: IOrganisationInvite) => void;
}

export default function PendingInviteItem({
  invite,
  isResendInviteLoading,
  onResendInvite,
  onDeleteInvite,
}: PendingInviteItemProps) {
  const renderRole = (role: string) => {
    switch (role) {
      case 'org_admin':
        return 'Admin';
      case 'org_editor':
        return 'Group Manager';
      default:
        return 'N/A';
    }
  };

  return (
    <li key={`invitation-${invite.id}`}>
      <div className="block bg-white">
        <div className="flex flex-row items-center justify-between py-4">
          <div className="min-w-0">
            <p className="text-sm font-medium text-gray-900 truncate">
              {invite.email}
            </p>
          </div>
          <div className="inline-flex flex-row space-x-3 items-center">
            <Badge kind={BADGE_KIND.YELLOW} text="Pending" />
            <Menu
              as="div"
              className="relative inline-block text-left mt-2 lg:mt-0"
            >
              <Menu.Button className="inline-flex justify-center w-max rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:ring-brand-500 focus:border-brand-500">
                {renderRole(invite.role)}
                <ChevronDownIcon
                  className="-mr-1 ml-2 h-5 w-5"
                  aria-hidden="true"
                />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute origin-top-right right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                  <div className="py-1">
                    <Menu.Item>
                      <button
                        type="button"
                        onClick={() => {
                          onResendInvite(invite);
                        }}
                        className="appearance-none text-gray-700 w-full text-left block px-4 py-2 text-sm"
                        disabled={isResendInviteLoading}
                      >
                        Resend invite
                      </button>
                    </Menu.Item>
                    <Menu.Item>
                      <button
                        type="button"
                        onClick={() => {
                          onDeleteInvite(invite);
                        }}
                        className="appearance-none text-red-500 w-full text-left block px-4 py-2 text-sm"
                      >
                        Delete invite
                      </button>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </li>
  );
}
