import { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { MapPinIcon, PhoneIcon } from '@heroicons/react/20/solid';
import {
  ArrowsRightLeftIcon,
  ArrowDownTrayIcon as DownloadIcon,
  LinkIcon as ExternalLinkIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import hexToRgba from 'hex-to-rgba';
import { useWindowSize } from 'usehooks-ts';

import { ReactComponent as BlankProfileAvatar } from '@/assets/icons/default_avatar.svg';
import { ReactComponent as DefaultHeaderImage } from '@/assets/svg/ProfilePageDefaultHeaderImage.svg';
import { isFileLink } from '@/helpers/file';
import { getFullName } from '@/helpers/strings';
import IFile from '@/types/IFile';
import {
  IProfileFileLink,
  IProfileQuickLink,
  IProfileSocialLink,
} from '@/types/IProfile';

import { mockProfileData } from './ProfilePage/data';
import { ProfileSocialLinks } from './SocialLinks';

type ProfileContent = {
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  pronouns: string | null;
  email: string | null;
  mobile_number: string | null;
  job_title: string | null;
  company_name: string | null;
  company_website: string | null;
  company_phone_number: string | null;
  profile_social_links: IProfileSocialLink[];
  profile_file_links: IProfileFileLink[];
  profile_quick_links: IProfileQuickLink[];
  photo: IFile | undefined;
};

type ProfilePreviewProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  settings: {
    bgColor: string;
    textColor: string;
    buttonBgColor: string;
    buttonTextColor: string;
    companyLogo: IFile | undefined;
  };
  content?: ProfileContent;
};

export function ProfilePreviewModal({
  isOpen,
  setIsOpen,
  settings,
  content,
}: ProfilePreviewProps) {
  const { bgColor, textColor, buttonBgColor, buttonTextColor, companyLogo } =
    settings;

  const { height } = useWindowSize();

  const {
    first_name: firstName,
    last_name: lastName,
    job_title: jobTitle,
    mobile_number: mobileNumber,
    middle_name: middleName,
    email,
    pronouns,
    profile_social_links: profileSocialLinks,
    profile_file_links: profileFileLinks,
    profile_quick_links: profileQuickLinks,
    company_name: companyName,
    company_phone_number: companyPhoneNumber,
    company_website: companyWebsite,
    photo,
  } = content ? content : mockProfileData;

  const links = [
    ...(profileFileLinks || []),
    ...(profileQuickLinks || []),
  ].sort((a, b) => a.order - b.order);

  const fullName = getFullName({
    firstName,
    middleName,
    lastName,
  });
  const sortedSocialLinks = [...(profileSocialLinks || [])].sort(
    (a, b) => a.order - b.order,
  );

  const hasSocials = sortedSocialLinks.length > 0;
  const hasLinks = links.length > 0;
  const hasCompanyPhoneNumber = !!companyPhoneNumber;
  const hasCompanyName = !!companyName;
  const hasCompanyWebsite = !!companyWebsite;
  const hasCompanyInformation =
    hasCompanyName || hasCompanyPhoneNumber || hasCompanyWebsite;

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <Transition appear show={isOpen}>
        <div className="min-h-screen px-4 flex flex-col space-y-2 items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-in duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-300 bg-opacity-40" />
          </Transition.Child>

          <div className="w-[356px] flex justify-end">
            <button
              type="button"
              className="rounded-full bg-white p-1 w-min self-end z-10"
              onClick={() => setIsOpen(false)}
            >
              <XMarkIcon width={30} height={30} className="text-gray-500" />
            </button>
          </div>
          <Transition.Child
            as={Fragment}
            enter="ease-in duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-out duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className="transition-all text-2xl transform shadow-xl rounded-4xl text-left overflow-hidden w-full max-w-[356px] max-h-[695px]"
              style={{
                backgroundColor: bgColor,
              }}
            >
              <div className="flex-1">
                <div
                  className="w-full absolute max-h-[250px] overflow-hidden"
                  style={{ height: height * 0.45 }}
                >
                  {companyLogo ? (
                    <img
                      src={companyLogo.thumb_url}
                      className="w-full h-full"
                      alt="Cover image"
                    />
                  ) : (
                    <DefaultHeaderImage />
                  )}
                </div>
                <div className="flex flex-col">
                  <div
                    className="w-full max-h-[250px]"
                    style={{ height: height * 0.45 }}
                  />
                  <div
                    className="rounded-tr-4xl p-4 -mt-8 relative"
                    style={{ color: textColor, backgroundColor: bgColor }}
                  >
                    <div className="-mt-16">
                      <div
                        className="rounded-full w-[88px] h-[88px] flex items-center justify-center overflow-hidden relative"
                        style={{
                          backgroundColor: bgColor,
                        }}
                      >
                        {photo ? (
                          <img src={photo.thumb_url} alt="Profile photo" />
                        ) : (
                          <BlankProfileAvatar width={85} height={85} />
                        )}
                      </div>
                      {fullName && (
                        <div className="font-semibold text-2xl pt-2 truncate">
                          {fullName}
                        </div>
                      )}
                      {pronouns && (
                        <div className="pt-1 font-light text-base">
                          {pronouns}
                        </div>
                      )}
                      {jobTitle && (
                        <div
                          className="text-base text-opacity-80"
                          style={{ color: hexToRgba(textColor, 0.8) }}
                        >
                          {jobTitle}
                        </div>
                      )}
                      <div className="h-[8px]" />
                      {mobileNumber && (
                        <div className="font-medium text-base">
                          {mobileNumber}
                        </div>
                      )}
                      <div className="space-y-3">
                        {email && (
                          <div className="font-medium text-base truncate">
                            {email}
                          </div>
                        )}
                        {hasSocials && (
                          <ProfileSocialLinks
                            links={profileSocialLinks}
                            textColor={textColor}
                          />
                        )}
                        {hasLinks && (
                          <div className="space-y-3">
                            <span className="text-lg font-medium">Links</span>
                            {links.map((link, index) => {
                              const { title } = link;

                              const Icon = isFileLink(link)
                                ? DownloadIcon
                                : ExternalLinkIcon;

                              return (
                                <div
                                  key={index}
                                  className="border rounded-full flex items-center justify-between text-sm px-4 py-2"
                                  style={{
                                    borderColor: textColor,
                                    color: textColor,
                                  }}
                                >
                                  <span className="w-5/6 truncate">
                                    {title}
                                  </span>
                                  <Icon width={20} height={20} />
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {hasCompanyInformation && (
                          <div className="w-full divide-y divide-gray-300">
                            <div
                              className="font-medium text-lg pb-2"
                              style={{
                                color: textColor,
                              }}
                            >
                              {companyName || 'Company Information'}
                            </div>
                            {hasCompanyName && (
                              <div
                                className="flex items-center py-2 text-base gap-1"
                                style={{ color: textColor }}
                              >
                                <MapPinIcon
                                  width={20}
                                  height={20}
                                  color={hexToRgba(textColor, 0.5)}
                                />
                                {companyName}
                              </div>
                            )}
                            {hasCompanyPhoneNumber && (
                              <div
                                className="flex items-center py-2 text-base gap-1"
                                style={{ color: textColor }}
                              >
                                <PhoneIcon
                                  width={20}
                                  height={20}
                                  color={hexToRgba(textColor, 0.5)}
                                />
                                {companyPhoneNumber}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="absolute w-full h-20 flex items-center justify-center bottom-0 px-4">
                <div
                  className="rounded-full flex items-center justify-center gap-1 text-base h-12 w-full"
                  style={{
                    backgroundColor: buttonBgColor,
                    color: buttonTextColor,
                  }}
                >
                  Connect
                  <ArrowsRightLeftIcon width={20} height={20} strokeWidth={2} />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Transition>
    </Dialog>
  );
}
